<template>
	<div class="accordeon">
		<div v-for="(row, i) in theListData" :key="i" class="row">
			<input type="checkbox" />
			<div class="title">
				<BaseIcon name="expand" /><span v-html="row.title.replace(/^_/, '')"></span>
			</div>
			<component
				:is="
					(options.ol && !row.title.match(/^_/)) || (!options.ol && row.title.match(/^_/))
						? 'ol'
						: 'ul'
				"
			>
				<li
					v-for="(child, j) in row.children"
					:key="j"
					:class="{ pad: options.pad }"
					v-html="child"
				></li>
			</component>
		</div>
	</div>
</template>
<script>
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'AccordeonList',
	components: {
		BaseIcon,
	},
	props: {
		listData: {
			type: Object,
			required: true,
		},
		options: {
			type: Object,
			default: () => {
				return {
					pad: false, // Wider gap between list items.
					ol: false, // Make all lists ordered lists. (This can also be achieved by prefixing the title with underscore: _Title)
				}
			},
		},
	},
	computed: {
		theListData() {
			return Object.keys(this.listData).map(key => ({
				title: key,
				children: this.listData[key],
			}))
		},
	},
}
</script>

<style scoped lang="scss">
.row {
	width: 100%;
	position: relative;
	border-bottom: solid 1px $black-10;
}
.row:first-child {
	border-top: solid 1px $black-10;
}
.accordeon .title {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 0.4rem;
	line-height: 0.4rem;
	display: flex;
	align-items: center;
	gap: 0.05rem;
	pointer-events: none;
}
.accordeon .title:deep() .icn {
	transition: transform 70ms linear;
	flex: 0 0 0.2rem;
}
.accordeon .title:deep() span {
	// Truncate
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: $regular;
}
.accordeon input[type='checkbox'] {
	display: block;
	width: 100%;
	height: 0.4rem;
	opacity: 0;
	cursor: pointer;
}
.accordeon input[type='checkbox']:checked + .title:deep() .icn {
	transform: rotate(90deg);
}
.accordeon input[type='checkbox']:not(:checked) ~ ul,
.accordeon input[type='checkbox']:not(:checked) ~ ol {
	display: none;
}
.accordeon ul,
.accordeon ol {
	margin: -0.05rem 0 0.15rem 0.25rem;
}
</style>
