<template>
	<h1>Accordeon List</h1>
	<div class="text-block bump-b-30">
		<p>Accordeon lists do not rely on javascript to expand and collapse.</p>
	</div>

	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-html">{{html.join('\n')}}</code></pre>
		<pre
			v-highlightjs
		><code class="language-javascript">import AccordeonList from '@/components/AccordeonList'

export default {
	components: { AccordeonList },
	data() {
		return {
			listData: {
				Vegetables: ['Potato', 'Carrot', 'Leek'],
				Fruits: ['Orange', 'Apple', 'Peach'],
				// Underscore flips the default ul/ol setting.
				'_Numbered List': ['One', 'Two', 'Three'],
				// HTML allowed.
				'&#60;i&#62;HTML&#60;/i&#62; Content': [
					'This is &#60;a href="#"&#62;a link&#60;/a&#62;.',
					'This is &#60;b&#62;some bolde text&#60;/b&#62;.'
				],
			},
		}
	},
}
</code></pre>
	</div>

	<h3>Options</h3>
	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-javascript">{
	pad: Boolean // Higher padding between list items.
	ol: Boolean // Use ordered lists by default (ol instead of ul).
}</code></pre>
	</div>

	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		<div id="list-wrap">
			<AccordeonList :listData="listData" :options="{ pad: true }" />
		</div>
	</div>
</template>

<script>
// Components
import AccordeonList from '@/components/AccordeonList'

// Internal
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocAccordeonList',
	components: { AccordeonList },
	setup() {
		injectMetaData({
			title: 'Doc: Accordeon List',
		})
	},
	data() {
		return {
			html: ['<AccordeonList :listData="listData" :options="{ pad: true }" />'],
			listData: {
				Vegetables: ['Potato', 'Carrot', 'Leek'],
				Fruits: ['Orange', 'Apple', 'Peach'],
				'_Numbered List': ['One', 'Two', 'Three'],
				'<i>HTML</i> Content': ['This is <a href="#">a link</a>.', 'This is <b>some bolde text</b>.'],
			},
		}
	},
}
</script>

<style scoped lang="scss">
#list-wrap {
	width: 600px;
}
</style>
